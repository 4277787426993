import React, { useEffect, useState } from "react";
import ContentElement, { getConditionalState } from "./ContentElement";
import ToolTip from "../ToolTip";

import "./TableQuestion.css";

const TableQuestion = ({
  isVisible,
  session,
  validator,
  onAddressChanged,
  onUpdating,
  content,
  disableField,
  survey,
  processedConfig,
  state,
  setState,
}) => {
  const [editMode, setEditMode] = useState({
    contentId: "",
  });

  const visibleColumns = content.columns
    .map((column) => {
      const state = getConditionalState(
        column.conditionalState,
        session.answers
      );

      return state === "SHOW" ? column.order : null;
    })
    .filter(Boolean);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setEditMode({
          contentId: "",
        });
      }
    };
    const handleClickOutside = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const table = e.target.closest("table");
      const td = e.target.closest("td");

      if (table && td) {
        const contentId = td.getAttribute("data-content-id");

        if (contentId) {
          setEditMode({ contentId });
        } else {
          setEditMode({ contentId: "" });
        }
      } else {
        setEditMode({ contentId: "" });
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const processContent = (content) => {
    if (content._id === editMode.contentId) return content;

    const answer = session?.answers?.find(
      (answer) => answer.field === content.field
    );

    let body = answer ? answer.value : "";

    if (content.options?.length > 0) {
      body = content.options
        .reduce((acc, opt) => {
          if (body.split(",").includes(opt.value)) {
            acc.push(opt.displayName);
          }

          return acc;
        }, [])
        .join(", ");

      if (body === "") body = "Select";
    }

    return {
      ...content,
      title: content.body ? content.title : "",
      body: `
        <div class="${body ? "text-clamp" : ""}">
          ${body || content.title}
        </div>
      `,
      type: "html",
    };
  };

  return (
    <div
      className={`table-responsive ${
        content.state === "HIDE" || !isVisible ? "d-none" : "d-block"
      }`}
      style={{
        paddingLeft: content.parentTextId ? "35px" : "0",
      }}
    >
      {content.title && (
        <h3>
          <div className="d-flex">
            <span>{content.title}</span>
            {content.infoField && (
              <ToolTip
                content={<span>{content.infoField}</span>}
                direction="right"
              >
                <i className="bi bi-info-circle icon"></i>
              </ToolTip>
            )}
          </div>
        </h3>
      )}
      <table className="table">
        <thead>
          <tr>
            {[...content.columns]
              .sort((a, b) => a.order - b.order)
              .filter((column) => visibleColumns.includes(column.order))
              .map((column) => (
                <th
                  key={column.order}
                  scope="col"
                  style={{
                    width: `${
                      column.width ? column.width : 100 / content.columns.length
                    }%`,
                  }}
                >
                  {column.title}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {[...content.rows]
            .sort((a, b) => a.order - b.order)
            .filter((row) => {
              const state = getConditionalState(
                row.conditionalState,
                session.answers
              );

              return state === "SHOW";
            })
            .map((row) => (
              <tr key={row.order}>
                {[...row.values]
                  .sort((a, b) => a.column - b.column)
                  .filter((value) => visibleColumns.includes(value.column))
                  .map(({ content }) => (
                    <td
                      key={content._id}
                      style={{
                        cursor: content.field ? "auto" : "not-allowed",
                      }}
                      data-content-id={content.field ? content._id : ""}
                    >
                      <ContentElement
                        session={session}
                        validator={validator}
                        onAddressChanged={onAddressChanged}
                        onUpdating={onUpdating}
                        content={processContent({ ...content })}
                        disableField={disableField}
                        survey={survey}
                        processedConfig={processedConfig}
                        state={state}
                        setState={setState}
                      />
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

// TableQuestion.defaultProps = {};

export default TableQuestion;
