import React from "react";

const classType = {
  secondary: "btn-light border-dark",
  primary: "btn-primary text-light",
};

const Button = ({
  children,
  className = "",
  onClick,
  primary = false,
  type = "button",
  isDisabled,
  id,
}) => {
  const classNames = `btn ${
    primary ? classType.primary : classType.secondary
  } ${className}`;
  return (
    <button
      onClick={onClick}
      className={classNames}
      type={type}
      disabled={isDisabled}
      id={id}
    >
      {children}
    </button>
  );
};

// Button.defaultProps = {
//   className: "",
//   primary: false,
//   type: "button"
// };

export default Button;
