import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Doughnut2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

FusionCharts.options.license({
  key:
    'AA-65A15wje1E5C1E4B1A17B2A3C1B1B1B3D1E2gvxC3E5qyuiI-7B6B1mA-13tF5H2E4J2A4D4B4C6E2E1F4E1H1A7C8A3cE-11B-9F2A2H2vB2D3D2knyE1C3C11gapB4E4D4kH-7C-21D7E4F4C1H5G1G1I4B6C1E3pzzC1G4AB3zD-13mD1D3G4dwB14XB6C6mmC-8D3J2A9B2C1E7E5F1C4D4B1B9A6B2d==',
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, Doughnut2D, FusionTheme);

export const FUSION_CHARTS_TYPE = {
  Doughnut2D: 'doughnut2d',
  Pie2D: 'pie2d',
  MSCombiDY2D: 'mscombidy2d',
  MSCombi2D: 'mscombi2d',
  MSStackedColumn2DLineDY: 'msstackedcolumn2dlinedy',
  MSStackedColumn2D: 'msstackedcolumn2d',
  StackedColumn2DLineDY: 'stackedcolumn2dlinedy',
  StackedColumn2DLine: 'stackedcolumn2dline',
  StackedColumn2D: 'stackedcolumn2d',
  StackedArea2DLineDY: 'stackedarea2dlinedy',
  StackedArea2D: 'stackedarea2d',
  StackedBar2D: 'stackedbar2d',
  StackedBar2DWithLine: 'msstackedcolumn2dlinedy',
};

const defaultChartConfigs = {
  type: 'doughnut2d',
  width: '100%',
  dataFormat: 'json',
};

const getChartConfig = ({ chartConfig, height, data }) => {
  return {
    ...defaultChartConfigs,
    height: height,
    dataSource: {
      chart: {
        ...chartConfig,
        paletteColors:
          '5D62B4,28C3BE,F27370,FFC532,61B58E,BC96DF,67CDF2,E58403,60CA9E,8F5097,569C3F,2797F1,BF5119,306004,32372E,5C97E0,2D6CA2,5B6770,8F6A2A,5F407A,872B4B,572C5F,244C5A,2596BE',
      },
      data: data,
    },
  };
};

export default function Donut2DChart({ chartConfig, height, data }) {
  return <ReactFC {...getChartConfig({ chartConfig, height, data })} />;
}
