import React from "react";
import "./Step.scss";

const Step = ({ active, children, completed, label }) => {
  const fillClassName =
    active || completed ? "step-complete" : "step-incomplete";
  return (
    <button className={`btn btn-link ${fillClassName}`}>
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <svg width="25px" viewBox="0 0 25 25">
            <circle cx="50%" cy="50%" r="12.5" className={fillClassName} />
            <text x="50%" y="50%" textAnchor="middle" dy=".35em" fill="#fff">
              {label}
            </text>
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
        <div className="text-label text-dark pl-3">{children}</div>
      </div>
    </button>
  );
};

// Step.defaultProps = {
//   disabled: false,
// };
export default Step;
